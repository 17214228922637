/******* Angular Resourse *******/
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Input } from '@angular/compiler/src/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/******* Core Resourse *******/
import { CoreConfigService } from '@core/services/config.service';
import { UtilsService } from '@core/services/utils.service';

/******* API Resourse *******/
import { Users, Auth } from '@api';

/******* Plug-In Resourse *******/
import { Subject } from 'rxjs';

/******* Tyep *******/
import { CoreConfig, SendingAuthCodeRequestBody } from '@core/types';
import { GlobalUIBlockingService } from '@core/services/global-ui-blocking.service';

@Component({
    selector: 'app-verify-twofa',
    templateUrl: './verify-twofa.component.html',
    styleUrls: ['./verify-twofa.component.scss']
})
export class VerifyTwofaComponent implements OnInit, OnDestroy {

    // Public
    public coreConfig: CoreConfig; // Layout Setting Config
    public codeForm: FormGroup; // code Form
    public submitted: boolean = false; // sumbmit flag
    public qrCodeContent: string = ""; // QR Code Content
    public loading: boolean = false;

    // Private
    private _unsubscribeAll$: Subject<any>; // UnsubscribeAll Observable
    private _queryParams: any;

    /**
     * constructor
     * 
     * @param {Users} _users
     * @param {Auth} _auth
     * @param {ElementRef} _elementRef
     * @param {Router} _router 
     * @param {ActivatedRoute} _route 
     * @param {FormBuilder} _formBuilder 
     * @param {CoreConfigService} _coreConfigService 
     * @param {UtilsService} _utilsService
     * @param {GlobalUIBlockingService} _globalUIBlockingService
     */
    constructor(private _users: Users,
        private _auth: Auth,
        private _elementRef: ElementRef,
        private _route: ActivatedRoute,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _coreConfigService: CoreConfigService,
        private _utilsService: UtilsService,
        private _globalUIBlockingService: GlobalUIBlockingService) {

        // initial observable item container
        this._unsubscribeAll$ = new Subject();

        // Set Blank Page
        this._coreConfigService.setConfig({
            layout: {
                footer: { hidden: true },
                menu: { hidden: true },
                navbar: { hidden: true },
                buyNow: false,
                customizer: false,
                scrollTop: false,
                enableLocalStorage: false,
                skin: "dark",
                type: "vertical"
            }
        });

        this.codeForm = this._formBuilder.group({
            code: ['', [Validators.required]]
        });
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * convenience getter for easy access to form fields
     */
    get f() {
        return this.codeForm.controls;
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Assign Query Data
        this._route.queryParams.subscribe((params) => {
            try {
                this._queryParams = params;
                this._getQrCode(params.email, params.token);
            }
            catch (error) {
                this._utilsService.alert("error", "Please Sign In again.", "Fail to resolve the data");
                this._router.navigate(['/login']);
            }
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * get qrcode
     * 
     * @param {string} email 
     * @param {string} token 
     */
    private _getQrCode(email: string, token: string): void {
        this._globalUIBlockingService.start();
        this._users.getQrCode({ email, token }).subscribe((returnData) => {
            this._globalUIBlockingService.stop();
            this.qrCodeContent = returnData.data.qrCode;
        }, (error) => {
            this._globalUIBlockingService.stop();
            this._utilsService.alert("error", "Fail to get QR code!", "Please refresh the page and try again.");
        })
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Submit the form
     * 
     * @returns 
     */
    public onSubmit(): void {

        // Submitted set true
        this.submitted = true;

        // stop here if form is invalid
        if (this.codeForm.invalid) {
            this._utilsService.scrollToInvalid(this._elementRef);
            return;
        }

        const codeValue = this.codeForm.getRawValue();
        let inputData: SendingAuthCodeRequestBody = {
            email: this._queryParams.email,
            authCode: codeValue.code
        };

        this._globalUIBlockingService.start();
        this._auth.checkAuthCode(inputData).subscribe((returnData) => {
            this._globalUIBlockingService.stop();
            if (returnData?.data?.isSuccessful) {
                this._utilsService.alert("success", "Set 2FA successfully", "");
                this._router.navigate(['/reset-password'], { queryParams: { ...this._queryParams, isTwoFactorActivity: true } });
            }
            else this._utilsService.alert("error", "Wrong auth code", "Please check again.");
        }, () => {
            this._globalUIBlockingService.stop();
            this._utilsService.alert("error", "Wrong auth code", "Please check again.");
        });
    }
}