/******* Angular Resourse *******/
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from "@angular/router";

/******* Shared Resourse *******/
import { UtilsService } from './utils.service';
import { GlobalUIBlockingService } from './global-ui-blocking.service';

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import * as _ from 'lodash';

/******* Core Resourse *******/
import { MENU as DefaultMenu, ACCOUNT_TYPE_PAGE_BLOCK, NOT_ADMIN_BLOCK, GENERAL_COLUMN, DEVICE_DATA_TABLE_DEF_OWNER, DEVICE_DATA_TABLE_DEF_GROUP, PERMISSION, DEFAULT_TENANT_BLOCK, TENANT_TYPE_BLOCK_PERMISSON } from '@core/constant';
import { CoreMenuItem, UpdateUserMeRequestBody, RolePermission, TenantType } from '@core/types';

/******* API Resourse *******/
import { Users } from '@api';
import { environment } from 'environments/environment';


@Injectable({
    providedIn: 'root',
})
export class PrivilegeService {

    // public
    public currentUser: Observable<any>;
    public currentTenants: Observable<any>;
    public currentBlockPath: Observable<Set<string>>;

    // private
    private currentUserSubject: BehaviorSubject<any>;
    private currentTenantsSubject: BehaviorSubject<any>;
    private currentBlockPathSubject: BehaviorSubject<Set<string>>;
    private qrCodeContent: string = "";
    private _privilegedMenu: CoreMenuItem[] = [];
    private _privilegedGroupMap: { [key: string]: Array<string> } = {};

    /**
     * Constructor
     *
     * @param {Router} _router
     * @param {UtilsService} _utilsService
     * @param {Users} _users
     * @param {GlobalUIBlockingService} _globalUIBlockingService
     */
    constructor(private _router: Router,
        private _utilsService: UtilsService,
        private _users: Users,
        private _globalUIBlockingService: GlobalUIBlockingService,) {

        // User initial
        this.currentUserSubject = new BehaviorSubject<any>(null);
        this.currentUser = this.currentUserSubject.asObservable();

        // Tenants initial
        this.currentTenantsSubject = new BehaviorSubject<any>(null);
        this.currentTenants = this.currentTenantsSubject.asObservable();

        // Path Block initial
        this.currentBlockPathSubject = new BehaviorSubject<Set<string>>(null);
        this.currentBlockPath = this.currentBlockPathSubject.asObservable();

        // Check Access Token Enable to reload User or not 
        this._router.events.pipe(filter(event => event instanceof RoutesRecognized), take(1)).subscribe((e: RoutesRecognized) => {
            const url = e.urlAfterRedirects;
            if (url.indexOf("/pages/") >= 0 && sessionStorage.getItem("becentralAccessToken")) {
                this.queryUserData();
            }
        });
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get current user value
     */
    get currentUserValue() {
        return this.currentUserSubject.value;
    }

    /**
     * Get current user value
     */
    get currentTenantsValue() {
        return this.currentTenantsSubject.value;
    }

    /**
     * Get current block path value
     */
    get currentBlockPathValue() {
        return this.currentBlockPathSubject.value;
    }

    /**
     * Get current API access privilege
     */
    get apiAccess() {
        return new Set(this.currentUserValue.permissions).has(RolePermission.FEATURES_REST_API) ||
            (this.isAdminAccess &&
                (this.currentUserValue?.tenant.type === TenantType.ISP_PRO ||
                    this.currentUserValue?.tenant.type === TenantType.TRIAL ||
                    this.currentUserValue?.tenant.type === TenantType.EVAL ||
                    this.currentUserValue?.tenant.type === TenantType.MSP_PARTNER_ENTERPRISE)
            );
    }

    /**
     * Get QR code image 
     */
    get qrCode() {
        return this.qrCodeContent;
    }

    /**
     * Get current privileged menu
     */
    get privilegedMenu() {
        return this._privilegedMenu;
    }

    /**
     * Get whether the user is super-admin
     */
    get isSuperAdminAccess() {
        return new Set(this.currentUserValue.permissions).has('SuperAdminAccess');
    }

    /**
     * Get whether the user is admin
     */
    get isAdminAccess() {
        return new Set(this.currentUserValue.permissions).has('AdministatorAccess');
    }

    /**
     * Get device data table def base on permission
     */
    get privilegedDeviceDataTableDef() {
        const isSuperAdminAccess = this.isSuperAdminAccess;
        return isSuperAdminAccess ? [...GENERAL_COLUMN, ...DEVICE_DATA_TABLE_DEF_OWNER] : [...GENERAL_COLUMN, ...DEVICE_DATA_TABLE_DEF_GROUP];
    }

    /**
     * Get whether the user is any group member
     */
    get isGroupMember() {
        return this.currentUserValue?.groups.length > 0;
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Query User Data
     */
    private queryUserData(resolve = null, rejects = null) {
        this._globalUIBlockingService.start();

        const promiseArray = [
            this._users.getPersonalInformation().toPromise(),
            this._users.getTenantsInformation().toPromise()
        ];

        Promise.all(promiseArray).then((returnDataArray) => {

            // User Data
            const userData = returnDataArray[0].data;
            if (userData.timeZone) sessionStorage.setItem("becentralTimeZone", userData.timeZone);
            this._privilegedMenu = [...this.loadPrivilegeMenu(userData)];
            if (userData.groups) {
                this._privilegedGroupMap = {};
                userData.groups.forEach(x => this._privilegedGroupMap[x.id] = x.permissions);
            }
            this.currentUserSubject.next(userData);

            // Tenant Data
            const tenantsData = returnDataArray[1].data;
            this.currentTenantsSubject.next(tenantsData);

            this._globalUIBlockingService.stop();
            if (resolve) resolve(userData);
        }).catch((error) => {
            this._utilsService.alert("error", "Fail to load User Data", error);
            this._globalUIBlockingService.stop();
            if (rejects) rejects(error);
        });
    }

    /**
     * Set User Data
     * 
     * @param {UpdateUserMeRequestBody} data 
     * @param resolve 
     * @param rejects 
     */
    private async setUserData(data: UpdateUserMeRequestBody, resolve = null, rejects = null): Promise<any> {

        this._users.updatePersonalInformation(data).pipe(
            switchMap((returnData) => {
                this.qrCodeContent = returnData?.data?.qrCode ? returnData.data.qrCode : "";
                return this._users.getPersonalInformation();
            }),
            catchError((error) => {
                return throwError(error);
            })
        ).subscribe((returnData) => {

            // User Data
            const userData = returnData.data;
            if (userData.timeZone) sessionStorage.setItem("becentralTimeZone", userData.timeZone);
            this._privilegedMenu = [...this.loadPrivilegeMenu(userData)];

            this.currentUserSubject.next(returnData.data);
            if (resolve) resolve(returnData.data);
        }, (error) => {
            this._utilsService.alert("error", "Fail to load User Data", error);
            if (rejects) rejects(error);
        });
    }

    /**
     * Load Privilege Menu
     * 
     * @param userData 
     */
    private loadPrivilegeMenu(userData: any): CoreMenuItem[] {
        if (!userData) return [];

        let menu = _.cloneDeep(DefaultMenu);
        let blockPage = {};
        let isGroupAdmin: boolean = false;

        // Check Admin Account
        if (!userData.permissions.includes('SuperAdminAccess') && !userData.permissions.includes('AdministatorAccess')) {
            blockPage = Object.assign(blockPage, { ...NOT_ADMIN_BLOCK });

            // Being Group Admin
            const found = userData.groups.find(x => new Set(x.permissions).has("GroupAdminAccess"));
            if (!found) blockPage = Object.assign(blockPage, { GROUPBUILDER: true });
            else isGroupAdmin = true;
        }

        // Check Account Type - Tenants Type
        blockPage = Object.assign(blockPage, ACCOUNT_TYPE_PAGE_BLOCK[userData.tenant.type]);

        // DEFAULT Tenant 
        if (userData.tenant.CustID === "DEFAULT")
            blockPage = Object.assign(blockPage, { ...DEFAULT_TENANT_BLOCK });

        let pathBlockSet = new Set<string>();
        this.travelAllMenuItem(menu, blockPage, pathBlockSet, userData.permissions, isGroupAdmin);
        this.currentBlockPathSubject.next(pathBlockSet);

        return menu;
    }

    /**
     * travel all Menu Item and set Permission
     * 
     * @param {Array<any>} menu 
     * @param {object} pageBlockObject 
     * @param {Set<string>} pathBlockSet 
     * @param {Array<string>} userPermissions
     * @returns 
     */
    private travelAllMenuItem(menu: Array<any>, pageBlockObject: object, pathBlockSet: Set<string>, userPermissions: Array<string>, isGroupAdmin: boolean = false): number {
        let itemCount = 0;
        menu.forEach((item) => {

            if (item.id === "MAP" && !environment.internetFunction) {
                item.hidden = true;
                this.addPathBlockSet(item, pathBlockSet);
                return;
            }

            if (pageBlockObject.hasOwnProperty(item.id)) {
                item.hidden = true;
                this.addPathBlockSet(item, pathBlockSet);
                return;
            }

            if (!this.checkMenuAccessByRole(item.id, userPermissions, isGroupAdmin)) {
                item.hidden = true;
                this.addPathBlockSet(item, pathBlockSet);
                return;
            }

            if (item.children) {
                const childrenCount = this.travelAllMenuItem(item.children, pageBlockObject, pathBlockSet, userPermissions, isGroupAdmin);
                if (childrenCount == 0) {
                    item.hidden = true;
                    this.addPathBlockSet(item, pathBlockSet);
                }
                else {
                    item.hidden = false;
                    itemCount++;
                }
            }
            else {
                item.hidden = false;
                itemCount++;
            }
        });
        return itemCount;
    }

    /**
     * Check Meun accessibility  by Role
     * 
     * @param {string} menuId
     * @param {Array<string>} userPermissions
     */
    private checkMenuAccessByRole(menuId: string, userPermissions: Array<string>, isGroupAdmin: boolean) {

        const permissionSet = new Set(userPermissions);

        // super-admin only need account setting page
        if (permissionSet.has("SuperAdminAccess")) {
            return menuId === "ACCOUNTSETTINGS" ||
                menuId === "DEVICES" ||
                menuId === "FILEMANAGEMENT";
        }

        // admin get all access
        if (permissionSet.has("AdministatorAccess")) return true;

        // role control
        switch (menuId) {
            case "DASHBOARDS":
                return permissionSet.has("ViewAnalytics");
            case "ANALYTICS":
                return permissionSet.has("ViewAnalytics");
            case "DEVICES":
                return true;
            case "GROUPBUILDER":
                return true;
            case "ALERTS":
            case "HISTORY":
                return permissionSet.has("FeatureAlert");
            case "SCHEDULER":
                return permissionSet.has("ViewScheduler");
            case "PROFILETEMPLATE":
                return permissionSet.has("ViewProfileTemplate");
            case "FILEMANAGEMENT":
                return permissionSet.has("ViewFileManagement");
            case "REPORT":
                return permissionSet.has("ViewReport");
            case "MAP":
                return permissionSet.has("ViewMap");
            case "RESTAPI":
                return permissionSet.has("FeatureRestApi");
            case "LOGS":
                return true;
            case "AUDITLOGS":
                return true;
            case "DOMAINPROXYLOGS":
                return true;
            case "ACCOUNTSETTINGS":
                return isGroupAdmin;
            case "SUPPORT":
                return true;
            default:
                return false;
        }
    }

    /**
     * Add Item into Path Block
     * 
     * @param {any} data 
     * @param {Set<string>} pathBlockSet 
     */
    private addPathBlockSet(data: any, pathBlockSet: Set<string>): void {
        if (data.url) pathBlockSet.add(data.url);
        else if (data.children) data.children.forEach((item) => { this.addPathBlockSet(item, pathBlockSet); });
        return;
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh User Data
     */
    public refreshUserData(): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this.queryUserData(resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Update user data
     * 
     * @param {UpdateUserMeRequestBody} inputData
     */
    public updateUserData(inputData: UpdateUserMeRequestBody): Promise<any> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this.setUserData(inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Clear the current user data
     */
    public clearUserInfo(): void {
        this.currentUserSubject.next(null);
    }

    /**
     * Check item accessibility by permissions
     * 
     * @param {string} permissionId
     * @returns {boolean}
     */
    public checkAccessByRole(permissionId: string): boolean {
        const permissionSet = new Set(this.currentUserValue.permissions ? this.currentUserValue.permissions : []);
        if (permissionSet.has("SuperAdminAccess")) return permissionId != 'FeatureAlert';
        if (permissionId === "SuperAdminAccess") return permissionSet.has("SuperAdminAccess");
        const accountTypePemissionSet = new Set(this.currentUserValue?.tenant.type ? TENANT_TYPE_BLOCK_PERMISSON[this.currentUserValue?.tenant.type] : []);
        if (accountTypePemissionSet.has(permissionId)) return false; // block by account type       
        return permissionSet.has("AdministatorAccess") ? true : permissionSet.has(permissionId);
    }

    /**
     * Check item accessibility by group permissions 
     * 
     * @param {string} groupId 
     * @param {string} permissionId 
     * @returns {boolean}
     */
    public checkAccessByGroupRole(groupId: string, permissionId: string): boolean {
        if (!groupId || !this.isGroupMember) return true;
        if (!this._privilegedGroupMap[groupId]) return false;
        const permissionSet = new Set(this._privilegedGroupMap[groupId]);
        if (permissionSet.has("GroupAdminAccess")) return true;
        else if (permissionSet.has("GroupDevice")) {
            return PERMISSION[1].children.map(x => x.id).includes(permissionId) ||
                PERMISSION[4].children.map(x => x.id).includes(permissionId)
        }
        else return permissionSet.has(permissionId);
    }
}