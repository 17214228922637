/******* Angular Resourse *******/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/******* Plug-In Resourse *******/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

/******* Core Resourse *******/
import { CoreCommonModule } from '@core/common.module';
import { BecentralRecaptchaModule, LanguageSelectorModule } from '@core/components';

/******* Component Resourse *******/
import { LoginComponent } from './login/login.component';
import { TwofaComponent } from './twofa/twofa.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TenantAccessComponent } from './tenant-access/tenant-access.component';
import { VerifyTwofaComponent } from './verify-twofa/verify-twofa.component';

/******* Auth Guard *******/
import { ResetPasswordGuard, TwoFactorAuthenticationGuard } from '@core/helpers';
import { FormElementModule } from '@core/components/form-element/form-element.module';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'twofa',
		canActivate: [TwoFactorAuthenticationGuard],
		component: TwofaComponent
	},
	{
		path: 'verify-twofa',
		canActivate: [ResetPasswordGuard],
		component: VerifyTwofaComponent
	},
	{
		path: 'forget-password',
		component: ForgotPasswordComponent
	},
	{
		path: 'reset-password',
		canActivate: [ResetPasswordGuard],
		component: ResetPasswordComponent
	},
	{
		path: 'tenant-access',
		component: TenantAccessComponent
	}
];

@NgModule({
	declarations: [
		LoginComponent,
		TwofaComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		VerifyTwofaComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		CoreCommonModule,
		SweetAlert2Module.forRoot(),
		BecentralRecaptchaModule,
		LanguageSelectorModule,
		FormElementModule
	]
})
export class AuthenticationModule { }
