/******* Angular Resourse *******/
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/******* Core Resourse *******/
import { CoreConfigService } from '@core/services/config.service';
import { passwordValidator, passwordMatch } from '@core/validators';
import { UtilsService } from '@core/services/utils.service';
import { AccessTokenService } from '@core/services/access-token.service';
import { AccountSwitchService } from '@core/services/account-switch.service';
import { CLIENT_ID, CLIENT_SECRET } from '@core/constant';

/******* Plug-In Resourse *******/
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/******* API Resourse *******/
import { Auth, Users } from '@api';

/******* Modal Template *******/
import { TermsOfUseModalComponent } from './modal-template/terms-of-use.component';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {

	// Public
	public coreConfig: any; // Layout Setting Config
	public resetPasswordForm: FormGroup; // Reset Password Form Group	
	public passwordTextType: boolean = false; // New Password type toggle flag	
	public confPasswordTextType: boolean = false; // Confirm Password type toggle flag	
	public submitted = false; // Submit flag	
	public loading = false; // Button loading flag

	// Private
	private _unsubscribeAll$: Subject<any>; // UnsubscribeAll Observable
	private _queryParameter: any; // query Parameter container
	@ViewChild('modal_term_of_use') modelTemplate;
	private _isTwoFactorAuthenticationActivate: boolean = false;

	/**
	 * constructor
	 * 
	 * @param {ActivatedRoute} _route 
	 * @param {Router} _router 
	 * @param {FormBuilder} _formBuilder 
	 * @param {ElementRef} _elementRef
	 * @param {Users} _users
	 * @param {Auth} _auth
	 * @param {CoreConfigService} _coreConfigService 
	 * @param {NgbModal} _ngbModal 
	 * @param {UtilsService} _utilsService 
	 * @param {AccessTokenService} _accessTokenService 
	 * @param {AccountSwitchService} _accountSwitchService
	 */
	constructor(private _route: ActivatedRoute,
		private _router: Router,
		private _formBuilder: FormBuilder,
		private _elementRef: ElementRef,
		private _users: Users,
		private _auth: Auth,
		private _coreConfigService: CoreConfigService,
		private _ngbModal: NgbModal,
		private _utilsService: UtilsService,
		private _accessTokenService: AccessTokenService,
		private _accountSwitchService: AccountSwitchService) {

		// initial observable item container
		this._unsubscribeAll$ = new Subject();

		// Build up the Form
		this.resetPasswordForm = this._formBuilder.group({
			newPassword: ['', [
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(30),
				passwordValidator("digit"),
				passwordValidator("capital"),
				passwordValidator("special"),
			]],
			confirmPassword: ['', [Validators.required]],
			termOfUse: [false, [Validators.requiredTrue]]
		}, {
			validators: [passwordMatch('newPassword', 'confirmPassword')]
		});

		// Set Blank Page
		this._coreConfigService.setConfig({
			layout: {
				footer: { hidden: true },
				menu: { hidden: true },
				navbar: { hidden: true },
				buyNow: false,
				customizer: false,
				scrollTop: false,
				enableLocalStorage: false,
				skin: "dark",
				type: "vertical"
			}
		});
	}

	//  Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * form getter 
	 * convenience getter for easy access to form fields
	 */
	get f() {
		return this.resetPasswordForm.controls;
	}

	/**
	 * form error getter 
	 */
	get formErrors() {
		return this.resetPasswordForm.errors;
	}

	/**
	 * get minlength valid condition
	 */
	get minlengthValid(): boolean {
		return this.f.newPassword.errors ? !this.f.newPassword.errors.minlength && !this.f.newPassword.errors.required : true;
	}

	/**
	 * get digit valid condition
	 */
	get digitValid(): boolean {
		return this.f.newPassword.errors ? !this.f.newPassword.errors.digit : true;
	}

	/**
	 * get capital valid condition
	 */
	get capitalValid(): boolean {
		return this.f.newPassword.errors ? !this.f.newPassword.errors.capital : true;
	}

	/**
	 * get special valid condition
	 */
	get specialValid(): boolean {
		return this.f.newPassword.errors ? !this.f.newPassword.errors.special : true;
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {

		// Assign Query Data
		this._route.queryParams.subscribe((params) => {
			this._queryParameter = params;
			this._isTwoFactorAuthenticationActivate = params.isTwoFactorActivity ? params.isTwoFactorActivity === "true" : false;
			this.verifyEmail(params);
		});

		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll$)).subscribe(config => {
			this.coreConfig = config;
		});
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll$.next();
		this._unsubscribeAll$.complete();
	}

	// Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Verify the Email
	 * 
	 * @param {any} params
	 */
	private async verifyEmail(params: any): Promise<void> {

		let inputData = {
			email: params.email,
			token: params.token,
			isTwoFactorActivity: this._isTwoFactorAuthenticationActivate
		};

		this._users.verifyResetPassword(inputData).subscribe(
			(results) => {
				if (!results.isSuccessful) {
					this._utilsService.alert('error', "Fail to Verify the Email.", "please check the link in email again.");
					this._router.navigate(['/login']);
				}
			},
			(error) => {
				this._utilsService.alert('error', "Fail to access the page.", error);
				this._router.navigate(['/login']);
			}
		)
	}


	/**
	 * Login Automatically
	 */
	private loginAutomatically(): void {

		let inputData = {
			"clientSecret": CLIENT_SECRET,
			"clientId": CLIENT_ID,
			username: this._queryParameter.email,
			password: this.f.newPassword.value
		};

		// Login 
		this._auth.login(inputData).subscribe(
			(results) => {

				const returnData = results.data;

				// call initial Access Token
				this._accessTokenService.initialAccessToken({
					code: returnData.code,
					clientId: CLIENT_ID,
					username: this._queryParameter.email
				}).subscribe((returnData) => {
					this._accountSwitchService.switchAccount(returnData.data); // Switch Account
				}, () => {
					this._utilsService.alert('error', "Fail to Sign In Automatically.", "");
					this.loading = false; // Release Button
					this._router.navigate(['/login']); // Redirect to login page
				});

			},
			(error) => {
				this._utilsService.alert('error', "Fail to Sign In Automatically.", error);
				this.loading = false; // Release Button
				this._router.navigate(['/login']); // Redirect to login page
			}
		)
	}

	// Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * toggle Password input type
	 * 
	 * @param type "new" or "cof"
	 */
	public togglePasswordTextType(type): void {

		let varName = type == "new" ? "passwordTextType" : "confPasswordTextType";

		this[varName] = !this[varName];
	}

	/**
	 * trigger Term Of Use Modal
	 * 
	 * @param event
	 */
	public displayTermsOfUse(event): void {

		event.preventDefault();

		this._ngbModal.open(TermsOfUseModalComponent, {
			centered: true,
			size: 'lg'
		}).result.then(
			(result) => {
				if (result == "Accept click")
					this.resetPasswordForm.controls.termOfUse.setValue(true);
			},
			(error) => { }
		);
	}

	/**
	 * Submit the form
	 */
	public onSubmit(): void {

		// Submitted set true
		this.submitted = true;

		// stop here if form is invalid
		if (this.resetPasswordForm.invalid) {
			this._utilsService.scrollToInvalid(this._elementRef);
			return;
		}

		// Block Button
		this.loading = true;

		let inputData = {
			email: this._queryParameter.email,
			token: this._queryParameter.token,
			password: this.f.newPassword.value,
			isTwoFactorActivity: this._isTwoFactorAuthenticationActivate
		};

		this._users.resetPassword(inputData).subscribe(
			(results) => {
				this._utilsService.alert("success", 'Success!', "Password has updated successfully.");
				this._router.navigate(['/login']); // Redirect to login page
				// this.loginAutomatically(); // Automatically login
			},
			(error) => {
				this.loading = false; // Release Button
				this._utilsService.alert("error", 'Fail to Update Passowrd', 'please check the link in email again.');
				this._router.navigate(['/login']); // Redirect to login page
			}
		)
	}
}
