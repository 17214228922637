import { ConfigMap, RolePermission } from "@core/types";

export const DEVICE_CONFIG_LAYOUT: ConfigMap = {
    defaultConfig: [
        {
            title: "Interface",
            items: [
                "Internet",
                "LAN",
                "PCI Lock"
            ],
            permissionId: RolePermission.CONFIG_INTERFACE
        },
        {
            title: "Wi-Fi",
            items: [
                "Wireless 2.4G",
                "Wireless 5G"
            ],
            permissionId: RolePermission.CONFIG_WIFI
        },
        {
            title: "Advanced Setup",
            items: [
                "Firewall",
                "Routing",
                "IPv6 Routing",
                "OSPF",
                "BGP",
                "NAT",
                "VRRP",
                "Static DNS",
                "Serial Port 1",
                "Serial Port 2",
                "RGOOSE",
                "PTP",
                "CBSD"
            ],
            permissionId: RolePermission.CONFIG_ADVANCED_SETUP
        },
        {
            title: "Access Management",
            items: [
                "Device Management",
                "SNMP",
                "Syslog",
                "Universal Plug and Play",
                "Dynamic DNS",
                "Access Control",
                "Packet Filter",
                "CWMP (TR-069)",
                "Parental Control",
                "BECentral"
            ],
            permissionId: RolePermission.CONFIG_ACCESS_MANAGEMENT
        },
        {
            title: "Maintenance",
            items: [
                "User Management",
                "Time Zone",
                /* "License",
                "Ignition Sensing", */
                "Auto Reboot"
            ],
            permissionId: RolePermission.CONFIG_MAINTENANCE
        }
    ],
    wzlrulConfig: [
        {
            title: "LAN",
            items: [
                "Ethernet",
                "Wireless"
            ]
        },
        {
            title: "WAN",
            items: ["WAN"]
        }
    ],
    _8920Config: [
        {
            title: "Wi-Fi Setting",
            items: ["Wi-Fi Setting"]
        },
        {
            title: "LAN DNS",
            items: ["LAN DNS"]
        },
        {
            title: "WAN DNS",
            items: ["WAN DNS"]
        }
    ],
    openWrtConfig: [
        {
            title: "Interface",
            items: [
                "Internet",
                "Band Selections"
            ],
            permissionId: RolePermission.CONFIG_INTERFACE
        },
        {
            title: "Wi-Fi",
            items: [
                "Wireless 2.4G",
                "Wireless 5G"
            ],
            permissionId: RolePermission.CONFIG_WIFI
        },
        {
            title: "Access Management",
            items: [
                "BECentral"
            ],
            permissionId: RolePermission.CONFIG_ACCESS_MANAGEMENT
        },
        {
            title: "LTE TTL",
            items: [
                "LTE TTL"
            ],
            permissionId: RolePermission.CONFIG_ADVANCED_SETUP
        }
    ]
};