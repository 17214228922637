<div class="auth-wrapper auth-v2">
	<div class="auth-inner row m-0 pb-0" style="overflow-x: hidden;">

		<div class="global-rllipse d-block d-lg-none"></div>

		<!-- Left Section-->
		<div class="d-flex col-lg-5 align-items-center auth-bg px-1 px-lg-2 login-form-section">
			<div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
				<div class="w-100 text-center mb-2">
					<img src="assets/images/logo/logo-white.png" alt="logo" height="60" />
				</div>
				<div class="login-form-header">
					<h1 [translate]="'LOGIN.GREETING.TITLE'">Welcome to BECentral!</h1>
					<p [translate]="'LOGIN.GREETING.TEXT'">Please sign-in to your account and start the adventure.</p>
				</div>

				<!-- Login Form -->
				<div class="d-flex align-items-center justify-content-center">
					<form class="auth-login-form mt-2 w-100" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
						<text-input [id]="'account-email'" [label]="'Email'" formControlName="username"
							[placeholder]="'admin@bec.com'" [submitted]="submitted"></text-input>
						<password-input [id]="'account-password'" [label]="'Password'" formControlName="password"
							[placeholder]="'············'" [submitted]="submitted"></password-input>
						<div class="d-flex justify-content-end">
							<a id="forget-password" routerLink="/forget-password">
								<small [translate]="'LOGIN.FORGOT_PASSWORD'">Forgot Password?</small>
							</a>
						</div>
						<!-- Sign In Button -->
						<button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block mt-2"
							tabindex="3" rippleEffect [translate]="'FORM.BUTTON_TEXT.SIGN_IN'">
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Sign in
						</button>
						<p class="mt-2" *ngIf="isInternetFunctionEnable">
							This site is protected by reCAPTCHA and the Google
							<a href="https://policies.google.com/privacy">Privacy Policy</a> and
							<a href="https://policies.google.com/terms">Terms of Service</a> apply.
						</p>
					</form>
				</div>
			</div>
			<!-- <div class="language-selector-container">
				<ul class="nav navbar-nav align-items-center ml-auto">
					<language-selector></language-selector>
				</ul>
			</div> -->
		</div>
		<div class="d-none d-lg-block col-lg-7 login-illustration-section">
			<div class="login-title">
				<div class="title-text text-center">
					<p class="main-text font-large-1">Connecting the World for a Better Tomorrow</p>
					<p class="subtitle-text">Developing Connectivity Solutions that Create Opportunities and Drive
						Growth</p>
				</div>
			</div>
			<div class="w-100 login-illustration-image">
				<div class="rllipse"></div>
				<img src="assets/images/pages/login-illustration.png" alt="Login" />
			</div>
		</div>
	</div>
	<div class="version-tag text-muted max-z-index"><small>{{currentVersion}}</small></div>
</div>