/******* Angular Resourse *******/
import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/******* Core Resourse *******/
import { CoreConfigService } from '@core/services/config.service';
import { UtilsService } from '@core/services/utils.service';
import { AccessTokenService } from '@core/services/access-token.service';
import { AccountSwitchService } from '@core/services/account-switch.service';
import { CODE_COUNT, CLIENT_ID, CLIENT_SECRET } from '@core/constant';

/******* API Resourse *******/
import { Auth } from '@api';

/******* Plug-In Resourse *******/
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/******* Tyep *******/
import { CoreConfig } from '@core/types';

@Component({
	selector: 'app-twofa',
	templateUrl: './twofa.component.html',
	styleUrls: ['./twofa.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TwofaComponent implements OnInit, OnDestroy, AfterViewInit {

	// Public
	public coreConfig: CoreConfig; // Layout Setting Config
	public codePanelForm: FormGroup; // code Panel Form
	public submitted: boolean = false; // sumbmit flag
	public loading: boolean = false; // button loading flag
	public queryParameter: { [key: string]: any } = {}; // query parameter

	// Private
	private _unsubscribeAll$: Subject<any>; // UnsubscribeAll Observable
	private errorCounting: number = 0;

	/**
	 * constructor
	 * 
	 * @param {Router} _router 
	 * @param {ActivatedRoute} _route 
	 * @param {FormBuilder} _formBuilder 
	 * @param {Auth} _auth 
	 * @param {CoreConfigService} _coreConfigService 
	 * @param {UtilsService} _utilsService
	 * @param {AccessTokenService} _accessTokenService
	 * @param {AccountSwitchService} _accountSwitchService
	 */
	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _formBuilder: FormBuilder,
		private _auth: Auth,
		private _coreConfigService: CoreConfigService,
		private _utilsService: UtilsService,
		private _accessTokenService: AccessTokenService,
		private _accountSwitchService: AccountSwitchService) {

		// initial observable item container
		this._unsubscribeAll$ = new Subject();

		// Set Blank Page
		this._coreConfigService.setConfig({
			layout: {
				footer: { hidden: true },
				menu: { hidden: true },
				navbar: { hidden: true },
				buyNow: false,
				customizer: false,
				scrollTop: false,
				enableLocalStorage: false,
				skin: "dark",
				type: "vertical"
			}
		});

		// Build up the Form
		this.codePanelForm = this._formBuilder.group({
			code: ['', [Validators.required]]
		});

		this.errorCounting = 2;
	}

	//  Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * convenience getter for easy access to form fields
	 */
	get f() {
		return this.codePanelForm.controls;
	}

	/**
	 * get code count
	 */
	get codeCount() {
		return CODE_COUNT;
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {

		// Assign Query Data
		this._route.queryParams.subscribe((params) => {
			try {
				this.queryParameter = { userAuth: JSON.parse(window.atob(params.token)) };
			}
			catch (error) {
				this._utilsService.alert("error", "Please Sign In again.", "Fail to resolve the data");
				this._router.navigate(['/login']);
			}
		});

		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll$)).subscribe(config => {
			this.coreConfig = config;
		});
	}

	/**
	 * After View initial
	 */
	ngAfterViewInit(): void {
		const inputElement: HTMLElement = document.getElementById('twofa-code');
		inputElement.focus();
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy(): void {
		this._unsubscribeAll$.next();
		this._unsubscribeAll$.complete();
	}

	// Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Keypress to control only input number
	 * 
	 * @param event event object
	 * @returns {boolean}
	 */
	public numericOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode < 48 || charCode > 57) {
			return charCode === 13;
		}
		return true;
	}

	/**
	 * Submit the form
	 * 
	 * @returns 
	 */
	public onSubmit(): void {

		// Submitted set true
		this.submitted = true;

		// stop here if form is invalid
		if (this.codePanelForm.invalid) {
			return;
		}

		let inputData = {
			"clientSecret": CLIENT_SECRET,
			"clientId": CLIENT_ID,
			username: this.queryParameter.userAuth.username,
			password: this.queryParameter.userAuth.password,
			authCode: this.f.code.value
		};

		this.loading = true;
		this._auth.login(inputData).subscribe(
			(results) => {
				let returnData = results.data;

				// call initial Access Token
				this._accessTokenService.initialAccessToken({
					code: returnData.code,
					clientId: CLIENT_ID,
					username: this.queryParameter.userAuth.username
				}).subscribe((returnData) => {
					this._accountSwitchService.switchAccount(returnData.data); // Switch Account
				}, () => {
					this._utilsService.alert('error', "Fail to Sign In.", "");
					this.loading = false; // Release Button
				});
			},
			(error) => {
				// Release Button
				this.loading = false;
				if (this.errorCounting <= 0) {
					this._utilsService.alert("error", "Fail to Sign In", error);
					this._router.navigate(['/login']);
				}
				else {
					this._utilsService.alert("error", "Two Factor Auth Fail", `For your security, you will have ${this.errorCounting} attempt before redirecting to login page.`);
					this.errorCounting--;
					this.f.code.setValue('');
					this.submitted = false;
					const inputElement: HTMLElement = document.getElementById('twofa-code');
					inputElement.focus();
				}
			}
		)
	}
}
