<div class="form-group">
    <label class="form-label" [for]="id">{{label}}</label>
    <div class="input-group input-group-merge" [ngClass]="{ 'is-invalid': invalid }">
        <input type="text" class="form-control" [ngClass]="{ 'is-invalid': invalid }" [id]="id" [(ngModel)]='value'
            [placeholder]="placeholder" [disabled]="isDisabled" (blur)="onTouch()" [autocomplete]="id"
            [maxlength]="maxlength">
    </div>
    <div *ngIf="invalid && displayErrorMessage" class="invalid-feedback">
        <ng-container *ngIf="isRequired && errors?.required">{{label}} is required</ng-container>
        <ng-container *ngIf="isEmail && errors?.email">Email format is incorrect</ng-container>
    </div>
</div>