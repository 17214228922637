import { ProductImage, SupportDeviceModel } from "@core/types";

export const PRODUCT_IMAGE: ProductImage = {
    [SupportDeviceModel.M_100]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.M_120_N]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/M120N.png",
    [SupportDeviceModel.M_150]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.M_300]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/M300.png",
    [SupportDeviceModel.M_300AX]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/M300AX.png",
    [SupportDeviceModel.M_500]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/M600.png",
    [SupportDeviceModel.M_600]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/M600.png",
    [SupportDeviceModel.MX_200]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_200E]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_200A]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_200AE]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_210_NP]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX210_241.png",
    [SupportDeviceModel.EG_210_N]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX210_241.png",
    [SupportDeviceModel.MX_220]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_220_UT]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-220-UT.png",
    [SupportDeviceModel.MX_221_P]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX210_241.png",
    [SupportDeviceModel.MX_230]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_240]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_241_NP]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX210_241.png",
    [SupportDeviceModel.MX_250]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_250E]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-200_220_series.png",
    [SupportDeviceModel.MX_600]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX-600.png",
    [SupportDeviceModel.MX_1000]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX1000.png",
    [SupportDeviceModel.MX_1000E]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX1000.png",
    [SupportDeviceModel.MX_1200]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/MX1200.png",
    [SupportDeviceModel._4500]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6300_6600_6500_8112.png",
    [SupportDeviceModel._4500AZ]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6300_6600_6500_8112.png",
    [SupportDeviceModel._4500NZ]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6300_6600_6500_8112.png",
    [SupportDeviceModel._4520AZ]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6300_6600_6500_8112.png",
    [SupportDeviceModel._4700]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/4700A.png",
    [SupportDeviceModel._4700AZ]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/4700AZ.png",
    [SupportDeviceModel._4700ZU]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/4700ZUL.png",
    [SupportDeviceModel._4900]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/4900.png",
    // 6200, 6300 ~
    [SupportDeviceModel._6500]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6300_6600_6500_8112.png",
    [SupportDeviceModel._6600]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6300_6600_6500_8112.png",
    // 6800;
    [SupportDeviceModel._6900]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/8232_7000_6900.png",
    _6900R21: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6900R21_7000R28.png",
    // 7000
    [SupportDeviceModel._7000]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/8232_7000_6900.png",
    [SupportDeviceModel._7000_R26_W]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/7000-R26-W.png",
    [SupportDeviceModel._8110]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6300_6600_6500_8112.png",
    [SupportDeviceModel._8112]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/6300_6600_6500_8112.png",
    [SupportDeviceModel._8230]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/8232_7000_6900.png",
    [SupportDeviceModel._8231]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/8231.png",
    [SupportDeviceModel._8232]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/8232_7000_6900.png",
    [SupportDeviceModel._8235]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/4900.png",
    [SupportDeviceModel._8355]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/8355.png",
    [SupportDeviceModel._8355P]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/8355.png",
    [SupportDeviceModel._9900]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/9900VA.png",
    [SupportDeviceModel._8920]: "https://becentral-spaces.sfo3.cdn.digitaloceanspaces.com/WebsiteImages/Products/8920AC.png",
    [SupportDeviceModel._8115]: ""
};

export const NOT_FOUND_IMAGE = "assets/images/pages/404.png";