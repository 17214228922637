import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewEncapsulation } from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";

@Component({
    selector: 'password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./form.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PasswordInputComponent)
        }
    ]
})
export class PasswordInputComponent implements ControlValueAccessor, OnInit {

    // Public
    @Input() id: string = "";
    @Input() label: string = "";
    @Input() placeholder: string = "";
    @Input() submitted: boolean = false;
    @Input() formControlName: string;
    @Input() displayErrorMessage: boolean = true;

    // Private
    private _onChange: (value: string) => void = (_: any) => { };
    private _onTouched: () => void = () => { };
    private _thisIsDisabled: boolean = false;
    private _thisValue: string = "";
    private _control: FormControl;
    private _isDisplay: boolean = false;

    /**
     * constructor
     * 
     * @param {ControlContainer} _controlContainer 
     */
    constructor(@Optional() @Host() @SkipSelf()
    private _controlContainer: ControlContainer) { }

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    get value(): string {
        return this._thisValue;
    }

    set value(_value: string) {
        this._thisValue = _value;
        this._onChange(this._thisValue);
    }

    get type(): string {
        return this._isDisplay ? 'text' : 'password';
    }

    get isDisplay(): boolean {
        return this._isDisplay;
    }

    get isDisabled(): boolean {
        return this._thisIsDisabled;
    }

    get invalid(): boolean {
        return this._control ? this._control.invalid && this.submitted : false;
    }

    get errors(): any {
        return this._control?.errors;
    }

    get isRequired(): boolean {
        return this._control.hasValidator(Validators.required);
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        if (this._controlContainer) {
            if (this.formControlName) this._control = this._controlContainer.control.get(this.formControlName) as FormControl;
            else console.warn('Missing FormControlName directive from host element of the component');
        } else console.warn('Can\'t find parent FormGroup directive');
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * writing value handler 
     * 
     * @param _value 
     */
    public writeValue(_value: string) {
        if (_value) this.value = _value;
    }

    /**
     * on change trigger 
     * 
     * @param fn 
     */
    public registerOnChange(fn: (value: string) => void): void {
        this._onChange = fn;
    }

    /**
     * on touched trigger 
     * 
     * @param fn 
     */
    public registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    /**
     * set disabled
     * 
     * @param isDisabled 
     */
    public setDisabledState?(isDisabled: boolean): void {
        this._thisIsDisabled = isDisabled;
    }

    /**
     * On Touch event handler
     */
    public onTouch(): void {
        this._onTouched();
    }

    /**
     * Toggle _isDisplay value 
     */
    public toggleDisplay(): void {
        this._isDisplay = !this._isDisplay;
    }
}