<li ngbDropdown class="nav-item dropdown dropdown-icon-wrapper">
    <a class="nav-link dropdown-toggle btn ml-50" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[translateService.currentLang].flag }}"></i>
        <span class="ml-50">{{ languageOptions[translateService.currentLang].name }}</span>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
            <span class="flag-icon flag-icon-{{ languageOptions[lang].flag }} mr-50"></span>
            {{ languageOptions[lang].name }}
        </a>
    </div>
</li>