/******* Angular Resourse *******/
import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

/******* Core Resourse *******/
import { CoreConfigService } from '@core/services/config.service';
import { AccountSwitchService } from '@core/services/account-switch.service';
import { UtilsService } from '@core/services/utils.service';
import { AccessTokenService } from '@core/services/access-token.service';
import { GlobalUIBlockingService } from '@core/services/global-ui-blocking.service';
import { CLIENT_ID, CLIENT_SECRET } from '@core/constant';
import { ReCaptchaV3Service } from '@core/services/recaptcha.service';

/******* API Resourse *******/
import { Auth } from '@api';

/******* Plug-In Resourse *******/
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/******* Type *******/
import { CoreConfig } from '@core/types';
import { environment } from 'environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.Emulated
})
export class LoginComponent implements OnInit, OnDestroy {

	// Public
	public coreConfig: CoreConfig; // Layout Setting Config
	public loginForm: FormGroup; // Login Form
	public submitted: boolean = false; // Form Submit Flag
	public loading: boolean = false; // Loading Flag

	// Private
	private _unsubscribeAll$: Subject<any>; // UnsubscribeAll Observable

	/**
	 * constructor
	 * 
	 * @param {Router} _router 
	 * @param {Title} _title
	 * @param {FormBuilder} _formBuilder
	 * @param {ElementRef} _elementRef
	 * @param {Auth} _auth
	 * @param {CoreConfigService} _coreConfigService
	 * @param {UtilsService} _utilsService
	 * @param {AccessTokenService} _accessTokenService
	 * @param {AccountSwitchService} _accountSwitchService
	 * @param {GlobalUIBlockingService} _globalUIBlockingService
	 * @param {ReCaptchaV3Service} _reCaptchaV3Service
	 */
	constructor(private _router: Router,
		private _title: Title,
		private _formBuilder: FormBuilder,
		private _elementRef: ElementRef,
		private _auth: Auth,
		private _coreConfigService: CoreConfigService,
		private _utilsService: UtilsService,
		private _accessTokenService: AccessTokenService,
		private _accountSwitchService: AccountSwitchService,
		private _globalUIBlockingService: GlobalUIBlockingService,
		private _reCaptchaV3Service: ReCaptchaV3Service) {

		// Clear Storage
		sessionStorage.clear();
		localStorage.clear();

		// Set Web Title to default "BECentral - BEC Technologies Inc.""
		this._title.setTitle("BECentral - BEC Technologies Inc.");

		// Build up the Form
		this.loginForm = this._formBuilder.group({
			username: ['', [Validators.required]],
			password: ['', [Validators.required]]
		});

		// Initial Observable
		this._unsubscribeAll$ = new Subject();

		// Set Blank Page
		this._coreConfigService.setConfig({
			layout: {
				footer: { hidden: true },
				menu: { hidden: true },
				navbar: { hidden: true },
				buyNow: false,
				customizer: false,
				scrollTop: false,
				enableLocalStorage: false,
				skin: "dark",
				type: "vertical"
			}
		});
	}

	//  Accessors
	// -----------------------------------------------------------------------------------------------------

	get currentVersion(): string {
		return environment.versionControl;
	}

	get isInternetFunctionEnable(): boolean {
		return environment.internetFunction;
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {

		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll$)).subscribe(config => {
			this.coreConfig = config;
		});

		this._globalUIBlockingService.enforceToStop();
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy(): void {

		// Unsubscribe from all subscriptions
		this._unsubscribeAll$.next();
		this._unsubscribeAll$.complete();
	}

	// Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Submit the form
	 */
	public onSubmit(): void {
		this.submitted = true;
		if (this.loginForm.invalid) {
			this._utilsService.scrollToInvalid(this._elementRef);
			return;
		}

		this.loading = true;
		this._reCaptchaV3Service.execute('LOGIN').then((token) => {
			const formValue = this.loginForm.value;
			let inputData = {
				"clientSecret": CLIENT_SECRET,
				"clientId": CLIENT_ID,
				...formValue,
				"captchaToken": token
			};
			this._auth.login(inputData).subscribe((results) => {
				const returnData = results.data;

				if (returnData.code) {
					// call initial Access Token
					this._accessTokenService.initialAccessToken({
						code: returnData.code,
						clientId: CLIENT_ID,
						username: formValue.username
					}).subscribe((returnData) => {
						this._accountSwitchService.switchAccount(returnData.data); // Switch Account
					}, (error) => {
						this._utilsService.alert('error', "Fail to Sign In.", error);
						this.loading = false; // Release Button
					});
				}
				else if (typeof returnData.isTwoFactorActivity != 'undefined') {
					if (!returnData.isTwoFactorActivity) {
						// send email for authCode due to reCAPTCHA
						this._auth.sendingAuthCode({ email: formValue.username }).subscribe(() => {
							this._utilsService.alert('warning', "reCAPTCHA low score", "Please check your email to complete 2-factor-authentication.");
						}, (error) => {
							this._utilsService.alert('error', "reCAPTCHA low score", error);
						});
					}
					const hash = window.btoa(JSON.stringify(this.loginForm.value));
					this._router.navigate(['/twofa'], { queryParams: { token: hash } });
				}
				else if (typeof returnData.verified != 'undefined' && !returnData.verified) {
					this._utilsService.alert('warning', "Email Unverified.", "Please access from your email to verify.");
				}

				this.submitted = false;
			}, (error) => {
				this._utilsService.alert('error', "Fail to Sign In.", error);
				this.loading = false; // Release Button
			});
		}).catch((error) => {
			this._utilsService.alert('error', "Fail to Sign In.", error);
			this.loading = false;
		});
	}
}
