<div class="auth-wrapper auth-v1 px-2 twofa-section">
	<div class="global-rllipse"></div>
	<div class="auth-inner py-2">
		<div class="card mb-0">
			<div class="card-body">
				<div class="w-100 text-center mb-2">
					<img src="assets/images/logo/logo-white.png" alt="logo" height="60" />
				</div>
				<div class="twofa-form-header">
					<h2 [translate]="'TWO_FACTOR_AUTH.GREETING.TITLE'">Two-factor Authentication</h2>
					<p [translate]="'TWO_FACTOR_AUTH.GREETING.TEXT'">Enter authentication code</p>
				</div>

				<form [formGroup]="codePanelForm" (ngSubmit)="onSubmit()">
					<text-input class="code-input" [id]="'twofa-code'" formControlName="code" [submitted]="submitted"
						[displayErrorMessage]="false" [maxlength]="6" (keypress)="numericOnly($event)"></text-input>
					<button type="submit" [disabled]="loading" class="btn btn-gradient-primary btn-block" rippleEffect>
						<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
						{{'FORM.BUTTON_TEXT.VERIFY_MY_ACCOUNT'|translate}}
					</button>
				</form>

				<p class="text-center mt-2">
					<a routerLink="/login" id="back-to-login">
						<i data-feather="chevron-left" class="mb-25"></i> {{'FORM.BUTTON_TEXT.BACK_TO_LOGIN'|translate}}
					</a>
				</p>
			</div>
		</div>
	</div>
</div>