<div class="navbar-container d-flex content">
	<div class="bookmark-wrapper d-flex align-items-center">
		<!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
		<ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
			<li class="nav-item">
				<a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
					<span [data-feather]="'menu'" [class]="'ficon'"></span>
				</a>
			</li>
		</ul>
		<!--/ Menu Toggler -->

		<!-- Toggle skin -->
		<div class="nav-item d-none d-lg-block">
			<a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
				<span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
					class="ficon font-medium-5 feather"></span>
			</a>
		</div>
		<!--/ Toggle skin -->

		<!-- Alert Center -->
		<ul class="nav navbar-nav align-items-center ml-auto">
			<app-navbar-notification *privilegedAccessControlItem="'FeatureAlert'"></app-navbar-notification>
		</ul>

		<!--/ Alert Center -->
		<!-- <ul class="nav navbar-nav align-items-center ml-auto">
			<language-selector></language-selector>
		</ul> -->

	</div>

	<ul class="nav navbar-nav align-items-center ml-auto">

		<!-- User Dropdown -->
		<li ngbDropdown class="nav-item dropdown-user">
			<a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
				id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
				<ng-container *ngIf="currentUser else loadingIcon">
					<!-- Text section -->
					<div class="user-nav d-sm-flex d-none unable-to-select">
						<span class="user-name font-weight-bolder mb-50">{{currentUser.email}}</span>
						<ng-container *ngIf="currentUser.isGroupAdmin else normalRole">
							<p class="user-status mb-0 nowrap-mixin" [ngbTooltip]="currentUser.groupRoles">
								{{currentUser.groupRoles}}
							</p>
						</ng-container>
						<ng-template #normalRole>
							<p class="user-status mb-0">
								{{currentUser.role | roleNameFilter}}
								<ng-container *ngIf="currentUser.tenant">({{currentUser.tenant.CustID}})</ng-container>
							</p>
						</ng-template>
					</div>

					<!-- Avatar -->
					<span class="avatar unable-to-select">
						<img *ngIf="currentUser.logoCDN; else customAvatar" class="round" height="40" width="40"
							[src]="currentUser.logoCDN" alt="Avatar Img" />
						<ng-template #customAvatar>
							<div class="d-flex align-items-center justify-content-center"
								style="height:40px;width:40px;">
								{{userFullName | initials}}
							</div>
						</ng-template>
						<span class="avatar-status-online"></span>
					</span>
				</ng-container>
				<ng-template #loadingIcon>
					<div class="spinner-grow" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</ng-template>
			</a>
			<div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
				<ng-container *ngIf="currentUser && currentUser.tenant.id != currentUser.defaultTenant">
					<div class="tenant-switch-container">
						<a ngbDropdownItem class="item" (click)="swapTenant()">
							<span class="avatar unable-to-select">
								<div class="d-flex avatar-circle">
									<span class="avatar-circle-text">D</span>
								</div>
							</span>
							DEFAULT
						</a>
					</div>
					<div class="dropdown-divider"></div>
				</ng-container>
				<ng-container *ngIf="isDefaultTenant">
					<a ngbDropdownItem [routerLink]="'/pages/profile'"><span [data-feather]="'user'"
							[class]="'mr-50'"></span> Profile</a>
					<div class="dropdown-divider"></div>
				</ng-container>
				<a ngbDropdownItem (click)="logout()">
					<span [data-feather]="'power'" [class]="'mr-50'"></span> Logout
				</a>
			</div>
		</li>
	</ul>
</div>