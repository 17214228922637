/******* Angular Resourse *******/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/******* Plug-In Resourse *******/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/******* Core Resourse *******/
import { CoreCommonModule } from '@core/common.module';

/******* Component Resourse *******/
import { TextInputComponent } from './text-input.component';
import { PasswordInputComponent } from './password-input.component';
import { TermsOfUseInputComponent } from './terms-of-use-input.component';

@NgModule({
    declarations: [
        TextInputComponent,
        PasswordInputComponent,
        TermsOfUseInputComponent
    ],
    imports: [CommonModule, NgbModule, CoreCommonModule],
    exports: [
        TextInputComponent,
        PasswordInputComponent,
        TermsOfUseInputComponent
    ]
})
export class FormElementModule { }